import Modaal from "modaal";
/**
 * TapingCourse
 * @constructor
 */
class TapingCourse {
  constructor() {}
  /**
   * TapingCourse
   */
  init() {
    $('.modal01').modaal();
    $('.modal02').modaal();
    $('.modal03').modaal();
    $('.modal04').modaal();
    $('.modal05').modaal();
    $('.modal06').modaal();
    $('.modal07').modaal();
    $('.modal08').modaal();
    $('.modal09').modaal();
    $('.modal10').modaal();
    $('.modal11').modaal();
    $('.modal12').modaal();
    $('.modal13').modaal();
    $('.modal14').modaal();
    $('.modal15').modaal();
    $('.modal16').modaal();
  }
};
export default TapingCourse;