
import ScrollMagic from "scrollmagic";
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import { gsap, Power3 } from "gsap";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);
const controller = new ScrollMagic.Controller;

/**
 * Scroll
 * @constructor
 */
class Scroll {
  constructor() {}
  /**
   * Scroll
   */
  init() {
        
        const outdoor = new TimelineMax();
        outdoor.from('.p-recoUse__ttl--outdoor', 0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        })
        .from('.p-recoUse__subTtl--outdoor', 0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.p-recoUse__text--outdoor', 0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.tag--outdoor',  0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.p-recoUse__img--outdoor01', 0.3, {
            opacity: '0',
        },'-=0.00')
        .from('.p-recoUse__img--outdoor02', 0.4, {
            opacity: '0',
        },'-=0.10')
        .from('.p-recoUse__img--outdoor03', 0.5, {
            opacity: '0',
        },'-=0.10')
        .from('.p-recoUse__img--outdoor04', 0.6, {
            opacity: '0',
        },'-=0.10')
        
        
        new ScrollMagic.Scene({
        triggerElement: '.p-recoUse__box--outdoor',
            triggerHook: '0.3',
            offset: '0',
            duration: '0',
            reverse: false,
        })
        
        .setClassToggle(".p-recoUse__bg--outdoor", "is-animated") 
        .setTween(outdoor)
        // .addIndicators({name: "outdoor"})
        .addTo(controller);
        
        
        const sportinlife = new TimelineMax();
        
        sportinlife.from('.p-recoUse__ttl--sportinlife', 0.5, {
            opacity: '0',
            transform: 'translateY(50px)',
        })
        .from('.p-recoUse__subTtl--sportinlife',0.5, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.p-recoUse__text--sportinlife', 0.5, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.tag--sportinlife', 0.5, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.p-recoUse__img--sportinlife01', 0.5, {
            opacity: '0',
        },'-=0.00')
        .from('.p-recoUse__img--sportinlife02', 0.6, {
            opacity: '0',
        },'-=0.10')
        .from('.p-recoUse__img--sportinlife03', 0.7, {
            opacity: '0',
        },'-=0.10')
        .from('.p-recoUse__img--sportinlife04', 0.8, {
            opacity: '0',
        },'-=0.10')
        
        
        new ScrollMagic.Scene({
            triggerElement: '.p-recoUse__box--sportinlife',
                triggerHook: '0.7',
                offset: '0',
                duration: '0',
                reverse: false,
        })
        .setClassToggle(".p-recoUse__bg--sportinlife", "is-animated") 
        .setTween(sportinlife)
        // .addIndicators({name: "sportinlife"})
        .addTo(controller);

        
        
            
        const dailylife = new TimelineMax();
        dailylife.from('.p-recoUse__ttl--dailylife', 0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        })
        .from('.p-recoUse__subTtl--dailylife',0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.p-recoUse__text--dailylife', 0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.tag--dailylife', 0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.p-recoUse__img--dailylife01', 0.3, {
            opacity: '0',
        },'-=0.00')
        .from('.p-recoUse__img--dailylife02', 0.4, {
            opacity: '0',
        },'-=0.10')
        .from('.p-recoUse__img--dailylife03', 0.5, {
            opacity: '0',
        },'-=0.10')
        .from('.p-recoUse__img--dailylife04', 0.6, {
            opacity: '0',
        },'-=0.10')
        
        
        new ScrollMagic.Scene({
        triggerElement: '.p-recoUse__box--dailylife',
            triggerHook: '0.3',
            offset: '0',
            duration: '0',
            reverse: false,
        })
        
        .setClassToggle(".p-recoUse__bg--dailylife", "is-animated") 
        .setTween(dailylife)
        // .addIndicators({name: "dailylife"})
        .addTo(controller);
        
        
        const work = new TimelineMax();
        work.from('.p-recoUse__ttl--work', 0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        })
        .from('.p-recoUse__subTtl--work',0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.p-recoUse__text--work', 0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.tag--work', 0.3, {
            opacity: '0',
            transform: 'translateY(50px)',
        },'-=0.20')
        .from('.p-recoUse__img--work01', 0.3, {
            opacity: '0',
        },'-=0.00')
        .from('.p-recoUse__img--work02', 0.4, {
            opacity: '0',
        },'-=0.10')
        .from('.p-recoUse__img--work03', 0.5, {
            opacity: '0',
        },'-=0.10')
        .from('.p-recoUse__img--work04', 0.6, {
            opacity: '0',
        },'-=0.10')
        
        
        new ScrollMagic.Scene({
        triggerElement: '.p-recoUse__bg--work',
            triggerHook: '0.3',
            offset: '0',
            duration: '0',
            reverse: false,
        })
        
        .setClassToggle(".p-recoUse__bg--work", "is-animated") 
        .setTween(work)
        // .addIndicators({name: "work"})
        .addTo(controller);
        
        
    }

  
};
export default Scroll;