import Swiper from "swiper";

/**
 * kvクラス
 * @constructor
 */
class Kv {
  constructor() {

  }
  /**
   * kv
   */
  init() {
    var mySwiper = new Swiper('.swiper-container', {
      loop: true,
      effect: "fade",
      speed: 1000, //1秒かけながら次の画像へ移動
      autoplay: {
        delay: 2000, //2秒後に次の画像へ
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }
  

  

}

export default Kv;
