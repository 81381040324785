/**
 * tabクラス
 * @constructor
 */
class Tab {
  constructor() {

  }
  /**
   * tab
   */
  init() {
    $('.js-tab').on('click', (e) => {
      this.tabMove(e.currentTarget);
    })
  }
  
  tabMove(el) {
      $('.is-active').removeClass('is-active');
      $(el).addClass('is-active');
      let num = $(el).data('box');
      $('.p-topTypeBox').css('display','none');
      $('#box_' + num).fadeIn();
  }

  

}

export default Tab;
