class Modal {
  constructor() {
    // ボタン要素
    this.btn = $(".js-modal-btn");
    // モーダルコンテンツ
    this.cont = $(".js-modal-cont");
    // 閉じるボタン
    this.close = $(".js-modal-close");
    // 画像部分
    this.img = $('.js-modal-img');
    // scrollさせない部分
    this.not_scroll = $("main");
  }

  /**
   * 実行
   */
  init() {
    this.openClick(this.btn, this.cont);
    this.closeClick(this.cont);
    this.fadeOut();
  }

  // 開くボタンクリック
  openClick(b, c) {
    b.on("click", (b) => {
      this.addActive(b.currentTarget, c);
      this.off_scroll();
      this.close.addClass('active');
    });
  }



  // モーダル領域をクリックでフェードアウト
  fadeOut() {
    this.cont.on("click", () => {
      this.removeActive(this.cont);
      this.on_scroll();
      this.close.removeClass('active');
    });
    // がしかし、画像をクリックでキャンセルさせる

    this.img.on("click", (e) => {
      e.stopPropagation();
    });

  }

  // 閉じるボタンクリック
  closeClick(c) {
    this.close.on("click", (c) => {
      this.removeActive(c);
      this.on_scroll();
      this.close.removeClass('active');
    });
  }


  // activeクラス付与
  addActive(b, c) {
    var i = this.btn.index(b);
    c.eq(i).addClass("active");
  }

  // activeクラス削除
  removeActive(c) {
    this.cont.removeClass("active");
  }

  // scroll不可能
  off_scroll() {
    $('html').css({
      overflow: 'hidden'
    });
  }

  // scroll可能
  on_scroll() {
    $('html').css({
      overflow: 'inherit'
    });
  }


};

export default Modal;
