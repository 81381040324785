
/**
 * kvクラス
 * @constructor
 */
class Movie {
  constructor() {

  }
  /**
   * kv
   */
  init() {
    $(".js-thumbBtn").on("click", function(){
        videoControl("playVideo",$(this).prev("iframe"));
        $(this).hide();
    });
    function videoControl(action,tgt){
      var $playerWindow = $(tgt)[0].contentWindow;
      $playerWindow.postMessage('{"event":"command","func":"'+action+'","args":""}', '*');
    }
  }
  

  

}

export default Movie;
