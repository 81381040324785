/**
 * アコーディオン
 * @constructor
 */
class Accordion {
  constructor() {

  }
  /**
   * アコーディオン
   */
  init() {
    const self = this;
    $('.js-openAcc').on('click', (e) => {
      self.accMovePc(e.currentTarget);
    })
    $('.js-openAccSp').on('click', (e) => {
      self.accMoveSp(e.currentTarget);
    })
    
    $('.js-click').on('click', function () {
      var trigger = $(this).parent().find('.p-supProUnder__aboutDetail');
      trigger.slideToggle();
      $(this).toggleClass('is-active');
      if($(this).hasClass('is-active')) {
        $(this).text('CLOSE');
      } else {
        $(this).text('OPEN');
      }
    });
  }

  accMovePc(el) {
    var pc = window.matchMedia("(min-width: 751px)").matches;
    if (pc) {
      $(el).find('.js-accBtn').toggleClass('is-open');
      $(el).next().slideToggle();
      $(el).toggleClass('is-active');
    }
  }

  accMoveSp(el) {
    var sp = window.matchMedia("(max-width: 750px)").matches;
    if (sp) {
      $(el).prev().slideToggle();
      $(el).toggleClass('is-active');
    }
  }
}







export default Accordion;
