/**
 * import node_modules
 */
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import SmoothScroll from 'smooth-scroll';
/**
 * import modules
 */
import Common from './modules/common';
import Tab from './modules/tab';
import Kv from './modules/kv';
import Accordion from './modules/accordion';
import Modal from './modules/modal';
import TapingCourse from './modules/tapingCourse';
import Scroll from './modules/scroll';
import Movie from './modules/movie';

/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();

    })
    window.addEventListener('load', () => {
      this.mounted();
    });
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
    picturefill();


    const common = new Common();
    common.init();

    const tab = new Tab();
    if ($('.l-tab').length > 0) {
      tab.init();
    }

    const kv = new Kv();
    if ($('.p-top').length > 0) {
      kv.init();
    }

    const accordion = new Accordion();
    accordion.init();

    const modal = new Modal();
    modal.init();

    const tapingCourse = new TapingCourse();
    tapingCourse.init();
    
    const scroll = new Scroll();
    scroll.init();
    
    const movie = new Movie();
    movie.init();
  }

  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {


    this.init_deSVG('.js-desvg');

    this.init_objectFitImages('.object-fit');

    this.init_smoothScroll();
  }



  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true)
  }

  /**
   * ライブラリ/object-fit-images
   * css object-fit のポリフィル
   * https://github.com/fregante/object-fit-images
   * @param {Element} target ターゲットとなる要素
   */
  init_objectFitImages(target) {
    objectFitImages(target);
  }

  /**
   * ライブラリ/picturefill
   * html pictureタグのポリフィル
   * https://scottjehl.github.io/picturefill/
   */
  init_picturefill() {
    picturefill();
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     */
    const targetElement = 'a[href*="#"]';
    
    $(window).on('load',function(){
      $(function() {
          let url = location.href;
          if(url.indexOf("?id=") != -1){
              let id = url.split("?id=");
              let $target = $('#' + id[id.length - 1]);
              if($target.length){
                  let position = $target.offset().top;
                  $("html, body").animate({scrollTop:position}, 1);
              }
          }
      });
    })

    /**
     * オプション
     * @type {object}
     */
    const option = {
      speed: 1000,
      easing: 'easeInOutCubic',
      speedAsDuration: true,
      // header: '[data-scroll-header]'//  固定ヘッダーの場合
    }

    new SmoothScroll(targetElement, option);
  }
}

export default new Main();
