/**
 * コモンクラス
 * @constructor
 */
class Common {
  constructor() {

  }
  /**
   * コモン
   */
  init() {
    $('.js-headerGnav').on('mouseenter', (e) => {
      this.headerMoOpen(e.currentTarget);
    });
    $('.js-headerGnav').on('mouseleave', (e) => {
      this.headerMoClose(e.currentTarget);
    });
    $('.js-spMenu,.js-btnType').on('click', (e) => {
      this.toggleSpMenu(e.currentTarget);
    });
    $('.js-SpNavArrow').on('click', (e) => {
      this.toggleSpNavListMenu(e.currentTarget);
    });
    $('.js-spMenu,.js-btnType').on('click', (e) => {
      this.spGnavToggle(e.currentTarget);
    });
    $('.js-SpNavArrow2').on('click', (e) => {
      this.toggleSpNavListMenu2(e.currentTarget);
    });
    $(window).on('load', () => {
      this.menu();
    });
    $('.js-btnType').on('click', () => {
      this.spTypeBtnAction();
    })
  }



  headerMoClose(el) {
    const target = $(el).find('.l-headerPcBottomList__btn');
    let data = target.data('gnav');
    target.removeClass('is-active');
    $('.js-gnav-' + data).fadeOut(200);
  }
  headerMoOpen(el) {
    const target = $(el).find('.l-headerPcBottomList__btn');
    let data = target.data('gnav');
    target.addClass('is-active');
    $('.js-gnav-' + data).fadeIn(200);
  }


  toggleSpMenu(el) {
    ($(el).hasClass('is-open')) ? this.closeSpMenu(el) : this.openSpMenu(el);
  }
  openSpMenu(el) {
    $(el).addClass('is-open');
    $(".l-headerSpTop__txt").text("CLOSE");
  }
  closeSpMenu(el) {
    $(el).removeClass('is-open');
    $(".l-headerSpTop__txt").text("MENU");
  }
  toggleSpNavListMenu(el) {
    ($(el).hasClass('is-open')) ? this.closeSpNavListMenu(el) : this.openSpNavListMenu(el);
  }
  closeSpNavListMenu(el) {
    const target = $(el).parents('.l-headerSpNavList__item').find('.l-headerSpNavListSub');
    target.slideUp(200);
    $(el).removeClass('is-open');
  }
  openSpNavListMenu(el) {
    const target = $(el).parents('.l-headerSpNavList__item').find('.l-headerSpNavListSub');
    target.slideDown(200);
    $(el).addClass('is-open');
  }


  toggleSpNavListMenu2(el) {
    ($(el).hasClass('is-open')) ? this.closeSpNavListMenu2(el) : this.openSpNavListMenu2(el);
  }
  closeSpNavListMenu2(el) {
    const target = $(el).parents('.l-headerSpNavListSub__item').find('.l-headerSpNavListSub2');
    target.slideUp(200);
    $(el).removeClass('is-open');
  }
  openSpNavListMenu2(el) {
    const target = $(el).parents('.l-headerSpNavListSub__item').find('.l-headerSpNavListSub2');
    target.slideDown(200);
    $(el).addClass('is-open');
  }



  spGnavToggle() {
    ($('.l-headerSp').hasClass('is-open')) ? this.spGnavClose() : this.spGnavOpen();
  }
  spGnavOpen() {
    $('.l-headerSp').addClass('is-open');
    $('.l-headerSpNav').addClass('is-open')
  }
  spGnavClose() {
    $('.l-headerSp').removeClass('is-open');
    $('.l-headerSpNav').removeClass('is-open')
  }

  spTypeBtnAction() {
    $('.l-headerSpTop__menu').toggleClass('is-open');
  }

  menu() {
    let urlPara = document.URL;
    if (urlPara.match(/vantelin/)) {
      $('.l-headerPcLeftList__item, .l-headerPcLeftList__img, .l-headerPcBottomListWrap, .l-headerSpBottomList__item, .l-headerSpBottomList__img, .l-headerSpNavList, .l-headerSp__area--02, .l-headerSp__area--03, .l-headerSp__area--04').removeClass('active');
      $('.l-headerPcLeftList__item--01, .l-headerPcLeftList__img--01, .l-headerPcBottomListWrap--01, .l-headerSpBottomList__item--01, .l-headerSpBottomList__img--01, .l-headerSpNavList--01, .l-headerSp__area--01').addClass('active');
      $('.js-sitemap').attr('href', '/vantelin/sitemap/');
    }
    if (urlPara.match(/supporter/)) {
      $('.l-headerPcLeftList__item, .l-headerPcLeftList__img, .l-headerPcBottomListWrap, .l-headerSpBottomList__item, .l-headerSpBottomList__img, .l-headerSpNavList, .l-headerSp__area--01, .l-headerSp__area--03, .l-headerSp__area--04').removeClass('active');
      $('.l-headerPcLeftList__item--02, .l-headerPcLeftList__img--02, .l-headerPcBottomListWrap--02, .l-headerSpBottomList__item--02, .l-headerSpBottomList__img--02, .l-headerSpNavList--02, .l-headerSp__area--02').addClass('active');
      $('.js-sitemap').attr('href', '/vantelin/supporter/sitemap/');
    }
    if (urlPara.match(/taping/)) {
      $('.l-headerPcLeftList__item, .l-headerPcLeftList__img, .l-headerPcBottomListWrap, .l-headerSpBottomList__item, .l-headerSpBottomList__img, .l-headerSpNavList, .l-headerSp__area--01, .l-headerSp__area--02, .l-headerSp__area--04').removeClass('active');
      $('.l-headerPcLeftList__item--03, .l-headerPcLeftList__img--03, .l-headerPcBottomListWrap--03, .l-headerSpBottomList__item--03, .l-headerSpBottomList__img--03, .l-headerSpNavList--03, .l-headerSp__area--03').addClass('active');
      $('.js-sitemap').attr('href', '/vantelin/taping/sitemap/');
    }
    if (urlPara.match(/recovery/)) {
      $('.l-headerPcLeftList__item, .l-headerPcLeftList__img, .l-headerPcBottomListWrap, .l-headerSpBottomList__item, .l-headerSpBottomList__img, .l-headerSpNavList, .l-headerSp__area--01, .l-headerSp__area--02, .l-headerSp__area--03').removeClass('active');
      $('.l-headerPcLeftList__item--04, .l-headerPcLeftList__img--04, .l-headerPcBottomListWrap--04, .l-headerSpBottomList__item--04, .l-headerSpBottomList__img--04, .l-headerSpNavList--04, .l-headerSp__area--04').addClass('active');
      $('.js-sitemap').attr('href', '/vantelin/recovery/sitemap/');
    }
  }





}

export default Common;
